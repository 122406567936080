<template>
    <div v-if="student" class="p-4">
        <div v-if="form" class="flex">
            <div class="w-full" v-if="student">

                <form @submit.prevent="store()" @keydown="removeError($event.target.id)">
                    <h3 class="text-2xl mb-2">Crear notificación para {{ student.first_name }} {{ student.last_name }}</h3>
                    <a-card>
                        <div class="flex flex-wrap -mx-2">
                            <div class="w-full">
                                <a-input
                                    label="Contenido"
                                    id="content"
                                    :error="error('content')"
                                    v-model="notification.content" />

                                <a-upload-input
                                    label="Archivo"
                                    class="mt-2"
                                    id="attachment"
                                    :error="error('attachment')"
                                    v-model="notification.attachment"/>
                            </div>
                        </div>
                    </a-card>

                    <div class="flex space-x-2">
                        <a-button type="submit" :loading="loading">
                            Enviar
                        </a-button>

                        <a-button outlined @click="form = false">
                            Cancelar
                        </a-button>
                    </div>
                </form>
            </div>
        </div>

        <template v-else>
            <div class="flex justify-center lg:justify-start">
                <h3 class="text-2xl mb-2">{{ displayingNotifications }} {{ student.first_name }} {{ student.last_name }}</h3>
            </div>

            <div class="flex flex-wrap justify-between mb-4 w-full">
                <a-segmented-control v-model="query.sent" :segments="segments" class="w-full lg:w-auto" />
                <a-button v-if="!user.isStudent" @click="form = true" class="w-full mt-4 lg:mt-0 lg:w-auto">
                    Crear notificación
                </a-button>
            </div>

            <template v-if="loading">
                <a-skeleton class="h-28 rounded-2xl w-full"></a-skeleton>
            </template>
            <template v-else-if="!loading && pagination.data.length > 0">
                <div class="grid xl:grid-cols-2 grid-cols-1 gap-4">
                    <NotificationCard
                        @deleted="fetch"
                        v-for="(notification, index) in pagination.data"
                        :key="index"
                        :disabled-options="{markAsRead: true, delete: disableById(user.id, notification)}"
                        :notification="notification" />
                </div>
<!--                <a-laravel-pagination align="right" :disabled="loading" :data="pagination" @change="handle({ ...query, page: $event })" class="mt-3"/>-->
            </template>
            <template v-else>
                <h1 class="text-2xl p-8 text-center">No se encontraron notificaciones</h1>
            </template>
        </template>
    </div>
    <a-card v-else>
        <a-skeleton :count="2" height="50px" />
    </a-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import NotificationCard from '@/views/notifications/components/notification-card'
export default {
    components: {
        NotificationCard
    },
    data: () => ({
        segments: [
            { id: 0, title: 'Recibidas' },
            { id: 1, title: 'Enviadas' },
        ],
        form: false,
        columns: [
            { title: 'De: ', key: 'sender.email', enabled: true },
            { title: 'Contenido: ', key: 'content', enabled: true },
            { title: 'Archivo', slot: 'attachment', enabled: true },
            { title: 'Acciones', slot: 'actions', alignment: 'right', enabled: true },
        ],
        query: {
            limit: 10,
            search: '',
            with: 'sender',
            sent: 0,
            page: 1,
            sort_by: 'created_at,desc'
        },
        timeout: null,
        notification: {
            content: '',
            type: 7,
            attachment: null,
            receiver_user_id: null,
            back:''
        }
    }),
    computed: {
        ...mapState({
            user: state => state.session.user,
            pagination({ notifications }) {

                if (this.user.isAdmin) {
                    return notifications.pagination
                }

                return {
                    ...notifications.pagination,
                    data: notifications.pagination.data
                        .filter($0 => $0.sender_user_id === this.user.id)
                }
            },
            loading: state => state.notifications.loading,
            student: ({ students }) => students.current
        }),
        ...mapGetters({
            hasError: 'hasError',
            error: 'error'
        }),
        displayingNotifications() {
            return `Notificaciones ${this.query.sent === 1 ? 'recibidas de' : 'enviadas a'}`
        }
    },
    methods: {
        ...mapActions({
            fetchStudentNotifications: 'notifications/fetchStudentNotifications',
            fetchNotifications: 'notifications/fetchAll',
            deleteNotification: 'notifications/delete',
            storeNotification: 'notifications/store',
            removeError: 'removeError',
        }),
        disableById(userId, notification) {
            if (notification.sender) {
              return userId === notification.sender.id
            } else {
              return false
            }
        },
        handle(query) {
            this.fetchStudentNotifications({
                id: this.student.id,
                filters: query
            })
        },
        toggleQueryFilters(query) {
            if (this.user.isAdmin) {
                this.fetchStudentNotifications({
                    id: this.student.id,
                    filters: query
                })
            } else {
                this.fetchNotifications({
                    ...query,
                    sent: query.sent === 0 ? 1 : 0,
                    receiver_user: this.student.user_id
                })
            }

        },
        fetch() {
            if (this.student) {
                this.notification.receiver_user_id = this.student.user_id;
                if (this.user.isAdmin) {
                    this.fetchStudentNotifications({
                        id: this.student.id,
                        filters: { ...this.query }
                    })
                } else {
                    this.fetchNotifications({
                        ...this.query,
                        sent: this.query.sent === 0 ? 1 : 0,
                        receiver_user: this.student.user_id
                    })
                }
            }
        },
        store(){
            this.storeNotification(this.notification)
                .then(() => {
                    if (this.user.isAdmin) {
                        this.fetchStudentNotifications({
                            id: this.student.id,
                            filters:{ ...this.query }
                        })
                    } else {
                        this.fetchNotifications(this.query)
                    }

                    this.form = false;
            })
        }
    },
    watch: {
        'query.sent'() {
            this.toggleQueryFilters(this.query)
        },
        student(val) {
            if (val) {
                this.notification.receiver_user_id = val.user_id;
                if (this.user.isAdmin) {
                    this.fetchStudentNotifications({
                        id: val.id,
                        filters: { ...this.query }
                    })
                }
                else {
                    this.fetchNotifications({
                        ...this.query,
                        sent: this.query.sent === 0 ? 1 : 0,
                        receiver_user: this.student.user_id
                    })
                }
            }
        }
    },
    mounted() {
        this.fetch()
    }
}
</script>
