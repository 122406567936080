<template>
    <div class="relative overflow-auto p-2 h-screen">
        <template v-if="!loading">
            <template v-if="!history">
                <h1 class="text-2xl text-center p-4">
                    No hay contenido
                </h1>
            </template>
            <div v-else class="my-4 absolute">
                <div class="flex flex-col items-start" v-if="currentSemester === 6 && passed === true && message === 'student_has_passed_all_courses'">
                    <span class="flex">El estudiante ha conseguido aprobar todos los semestres.</span>
                    <a outlined target="_blank" class="flex mt-2 border-2 border-blue-700 rounded-md p-2 text-blue-600"
                       :href="`${url}/api/students/${$route.params.student}/reports/courses-final-notes-global?token=${token}`">
                        Imprimir histórico de notas
                    </a>
                </div>
                <div v-for="(value, attribute, key) in history" :key="key" class="bg-white rounded-xl"
                     :class="{ 'mt-4': key > 0 }">
                    <div class="flex justify-between flex-wrap items-center space-x-2 mb-2 px-2 pt-2">
                        <h4 class="text-2xl">{{ attribute }}</h4>
                        <div class="flex space-x-2 flex-wrap">
                            
                            <a outlined target="_blank" class="border-2 border-blue-700 rounded-md p-2 text-blue-600"
                               :href="`${url}/api/students/${$route.params.student}/reports/courses-notes?period_id=${value[0].period_id}&cut=1&token=${token}`">
                                Imprimir notas corte 1
                            </a>
                            <a outlined target="_blank" class="border-2 border-blue-700 rounded-md p-2 text-blue-600"
                               :href="`${url}/api/students/${$route.params.student}/reports/courses-notes?period_id=${value[0].period_id}&cut=2&token=${token}`">
                                Imprimir notas corte 2
                            </a>
                            <a outlined target="_blank"
                               :href="`${url}/api/students/${$route.params.student}/reports/courses-final-notes?period_id=${value[0].period_id}&token=${token}`"
                               class="border-2 border-blue-700 rounded-md p-2 text-blue-600">
                                Boletín final
                            </a>
                        </div>
                    </div>
                    <a-table :columns="columns" :source="value">
                        <template #td(course)="{ item }">
                          <span
                              :class="item.pivot.failed_by_final_delivery ? 'text-red-400' : ''"
                              :title="item.pivot.failed_by_final_delivery ? 'Reprobada por entrega final' : ''"
                          >{{ item.course.title }}</span>
                        </template>
                        <template #td(finalNote)="{ item }">
                          <span
                              :class="item.final_delivery && item.pivot.final_delivery_note === 0 ? 'text-red-400' : ''"
                              :title="item.final_delivery && item.pivot.final_delivery_note === 0 ? 'No entregado' : ''"
                          >
                            {{ getFinalDeliveryNote(item) }}
                          </span>
                        </template>
                        <template #td(total)="{ item }">
                            {{ item.pivot.final_delivery_weight_note + item.pivot.cut_weight_note }}
                        </template>
                        <template #td(status)="{ item }">
                            {{ getStatus(item.pivot.status) }}
                        </template>
                        <!--          <template #td(final_note)="{ item }">-->
                        <!--            {{ item.pivot.overwritten_final_note || item.pivot.final_note }}-->
                        <!--            <a-badge v-if="item.pivot.overwritten_final_note !== null">Modificado</a-badge>-->
                        <!--          </template>-->
                    </a-table>
                </div>
            </div>
        </template>
        <div v-else class="space-y-2">
            <a-skeleton class="w-full h-16" v-for="n in 5" :key="n"/>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {URL} from '@/constants';

const token = localStorage.getItem(process.env.VUE_APP_USER_TOKEN_NAME)
export default {
    name: 'history',
    data: () => ({
        url: URL,
        token,
        history: null,
        columns: [
            {title: 'Materia', key: 'course.title', slot: 'course'},
            // { title: 'Credito anterior', key: '' },
            {title: 'Créditos', key: 'credits'},
            {title: 'I Corte', key: 'pivot.first_cut_total_note'},
            {title: 'II Corte', key: 'pivot.second_cut_total_note'},
            // {
            //     title: 'Promedio',
            //     key: 'pivot',
            //     mutate: ({key}) => key.second_cut_total_note ? key.cut_average_note : '-'
            // },
            {
                title: 'Ponderación total de la materia',
                mutate: ({key}) => key.first_cut_evaluation_weighting_prc + key.second_cut_evaluation_weighting_prc
            },
            {
                title: 'Entrega final',
                mutate: ({key}) => key.final_delivery ? key.final_delivery_evaluation_weighting_prc : 'N/A'
            },
            {
                title: 'Nota entrega final',
                key: 'pivot',
                slot: 'finalNote',
            },
            {title: 'Total Acumulado', key: 'pivot.final_note_raw'},
            {
                title: 'Final',
                key: 'pivot',
                mutate: ({key}) => key.overwritten_final_note !== null ? key.overwritten_final_note : key.final_note
            },
            {title: 'Estado', key: 'pivot.status', slot: 'status'}
        ],
        loading: false,
        passed: false,
        currentSemester: 0,
        message: ''
        //, mutate: ({key}) => key.toFixed(2)
    }),
    computed: {
        ...mapState({
            user: ({session}) => session.user
        }),

    },
    methods: {
        ...mapActions({
            fetchStudent: 'students/show',
        }),
        getStatus(status) {
            switch (status) {
                case 'withdrawn':
                    return 'Retirada'
                case 'withdrawn_by_equivalence':
                    return 'Retirado por equivalencia'
                case 'assigned':
                    return 'Asignada'
            }
        },
        getAuthToken() {
            return this.token ? `Bearer ${this.token}` : '';
        },
        getHistoryParts(attribute) {
            const year = attribute.slice(0, 3)
            const number = attribute.slice(3, 4)
            const semester = attribute.slice(4, 5)

            return {
                year,
                number,
                semester,
                period: `${year}-${number}`
            }
        }
      ,
      getFinalDeliveryNote (item) {
        // {{ item.pivot.final_delivery_note !== 0 && item.pivot.final_delivery_note !== null ? item.pivot.final_delivery_note : item.pivot.final_delivery_note === 0 ? 'N/E' : '-' }}

        if (item.final_delivery) {
          if(item.pivot.final_delivery_note === 0) {
            return 'N/E'
          } else {
            return item.pivot.final_delivery_note !== null ? item.pivot.final_delivery_note : '-'
          }
        } else {
          return 'N/A'
        }
      }
    },
    mounted() {

        const {student} = this.$route.params

        this.fetchStudent({
            id: student,
            query: {
                with: 'latestInscription.period,latestInscription.specialization,latestInscription.semester,latestInscription.section'
            }
        })
            .then(({data}) => {
                this.student = data
            })

        this.loading = !this.loading
        this.$repository.students
            .specializationHistory(student)
            .then(({data: response}) => {
                this.history = response
                // this.history = Object.assign([], response)

                // console.log(this.history)
                // console.log(this.history.forEach((key) => (console.log(key))))
            })
        this.$repository.students
            .verifySemesterApproval(student)
            .then((response) => {
                this.passed = response.data.passed;
                this.currentSemester = response.data.current_semester.number
                this.message = response.data.message
            }).finally(() => this.loading = !this.loading)
    }
}
</script>
